#section_2 {
  width: 955px;
  height: 100%;
  color: #111111;
  > .header_wrap {
    width: 100%;
    height: 85px;
    display: flex;
    align-items: end;
    padding-bottom: 15px;
    > h2 {
      margin-right: auto;
      font-size: 30px;
      font-weight: bold;
    }
    > .is_connect_wrap {
      display: flex;
      align-items: center;
      padding-right: 20px;
      > span {
        font-size: 14px;
        font-weight: 500;

        padding-right: 10px;
      }
      > #circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #7aff58;
      }
    }
    > .btn_link {
      color: black;
      text-decoration: none;
      > .btn_detail {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        margin-right: 10px;
        > #arrow {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-top: 1px solid #111111;
          border-right: 1px solid #111111;
          margin-left: 10px;
          transform: rotateZ(45deg);
        }
      }
    }
  }
  > .info_wrap {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: space-between;
    // padding: 10px 0px 10px 0px;
    > .text_wrap {
      display: flex;
      flex-direction: column;
      padding: 12px 25px;
      > .name {
        font-size: 30px;
        font-weight: bold;
      }
      > .address {
        font-size: 23px;
        font-weight: 500;
      }
      > .position {
        font-size: 16px;
        font-weight: 500;
      }
      > img {
        width: 109px;
        height: 32px;
        margin-top: auto;
      }
    }
    > .img_wrap {
      height: 220px;
    }
  }
  > .tips_wrap {
    width: 100%;
    height: 160px;
    padding: 20px 25px 0px 25px;
    margin-top: 18px;
    > .title {
      font-size: 20px;
      font-weight: 500;
    }
    > .tips {
      padding-top: 20px;
      font-size: 16px;
      font-weight: 300;
    }
  }
  > .sensor_detail_wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    > .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > h2 {
        font-size: 30px;
        font-weight: bold;
      }
      > .sensor_wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 486px;
        height: 100%;
        margin-top: 16px;
        > .card_wrap {
          width: 235px;
          height: 173px;
          position: relative;
          overflow: hidden;
          > .data_wrap {
            width: 100%;
            height: 100%;
            padding: 10px 20px 16px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            z-index: 1;
            > .upper {
              display: flex;
              justify-content: space-between;
              > .left {
                > .type {
                  font-size: 12px;
                  font-weight: 500;
                  color: #bfbfbf;
                }
                > .name {
                  font-size: 20px;
                  font-weight: 500;
                }
              }
              > .right {
                font-size: 20px;
                font-weight: 500;
              }
            }
            > .under {
              font-size: 40px;
              font-weight: bold;
              align-self: flex-end;
              > .unit {
                font-size: 20px;
              }
            }
          }
          > svg {
            position: absolute;
            top: 0;
            z-index: 0;
          }
        }
        > .small_card_wrap {
          width: 235px;
          height: 110px;
          padding: 10px 20px 16px 20px;
          > .data_wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > .upper {
              display: flex;
              justify-content: space-between;
              > .left {
                > .type {
                  font-size: 12px;
                  font-weight: 500;
                  color: #bfbfbf;
                }
                > .name {
                  font-size: 20px;
                  font-weight: 500;
                }
              }
            }
            > .under {
              font-size: 40px;
              font-weight: bold;
              align-self: flex-end;
              line-height: 30px;
              > span {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    > .right {
      > .total_wrap {
        width: 453px;
        height: 552px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 23px 29px;
        color: white;
        > .upper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > .left {
            > .type {
              font-size: 12px;
              font-weight: 500;
            }
            > .name {
              font-size: 20px;
              font-weight: bold;
            }
            > .comment {
              font-size: 16px;
              font-weight: 300;
            }
          }
          > .right {
          }
        }
        > .middle {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          > .ment {
            font-size: 30px;
            font-weight: 500;
          }
          > .label {
            position: absolute;
            top: 0;
            font-size: 14px;
          }
          > .label_1 {
            top: 70%;
            left: 7%;
          }
          > .label_2 {
            top: 20%;
            left: 47%;
          }

          > .label_3 {
            top: 70%;
            left: 84%;
          }
        }
        > .under {
          width: 100%;
          > .percentage {
            font-size: 70px;
            font-weight: bold;
          }
          > .detail {
            font-size: 20px;
            font-weight: 500;
            > span {
              font-size: 16px;
              font-weight: 500;
              background-color: white;
              border-radius: 20px;
              padding: 0px 16px;
              margin-left: 10px;
              margin-right: 20px;
            }
            > .level0 {
              color: #32a1ff;
            }
            > .level1 {
              color: #32a1ff;
            }
            > .level2 {
              color: #4cd964;
            }
            > .level3 {
              color: #ff993a;
            }
            > .level4 {
              color: #ce1212;
            }
          }
        }
      }
      > .level0 {
        background-color: #32a1ff;
      }
      > .level1 {
        background-color: #32a1ff;
      }
      > .level2 {
        background-color: #4cd964;
      }
      > .level3 {
        background-color: #ff993a;
      }
      > .level4 {
        background-color: #ce1212;
      }
    }
  }
}
