#section_1 {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 10px 4px 30px 0px rgba(0, 0, 0, 0.15);
  > .logo_wrap {
    width: 300px;
    height: 80px;
    padding: 25px;
    background-color: white;
  }
  > .sensor_btn_list {
    > .sensor_btn {
      width: 300px;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
      cursor: pointer;
      > .img_wrap {
        width: 250px;
        height: 230px;
        position: relative;
        border: 2px solid transparent;
        border-radius: 30px;
        overflow: hidden;
        // filter: drop-shadow(0px 0.25px 1px rgba(0, 0, 0, 0.039)) drop-shadow(0px 0.85px 3px rgba(0, 0, 0, 0.19));
        filter: drop-shadow(1px 5px 1px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0.85px 3px rgba(0, 0, 0, 0.3));
        > #img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        > #checked {
          position: absolute;
          right: 0;
          margin-top: 16px;
          margin-right: 16px;
        }
      }
      > .sensor_text {
        width: 200px;
        display: flex;
        justify-content: space-between;
        padding-top: 8px;
        color: #111111;
        font-weight: 500;
      }
    }
    > .active {
      background-color: #2f5427;
      > .img_wrap {
        border: 2px solid white;
      }
      > .sensor_text {
        color: white;
      }
    }
  }
}
