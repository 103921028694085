#graph_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  > .device_list_wrap {
    width: 224px;
    height: 100%;
    box-shadow: 10px 4px 30px 0px rgba(0, 0, 0, 0.15);
    > .name {
      width: 100%;
      height: 56px;
      background-color: #396030;
      color: white;
      font-size: 14px;
      font-weight: 500;
      padding: 16px 32px;
    }
    > ul {
      > li {
        display: flex;
        justify-content: space-between;
        padding: 16px 40px 16px 32px;
        font-size: 14px;
        font-weight: 500;
        > .checkbox_wrap {
          > input[type="checkbox"] {
            display: none;
          }
          > label {
            cursor: pointer;
          }

          > label:before {
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #a4a4a4;
            border-radius: 4px;
            vertical-align: middle;
          }

          > input[type="checkbox"]:checked + label:before {
            content: "";
            background-image: url("../../public/imgs/check_white.png");
            background-repeat: no-repeat;
            background-position: 50%;
          }
          > input[type="checkbox"]:checked + .color_0:before {
            background-color: #ff9900;
            border-color: #ff9900;
          }
          > input[type="checkbox"]:checked + .color_1:before {
            background-color: #32a1ff;
            border-color: #32a1ff;
          }
          > input[type="checkbox"]:checked + .color_2:before {
            background-color: #00c3b7;
            border-color: #00c3b7;
          }
        }
      }
    }
  }
  > main {
    width: 1600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    > .title_wrap {
      width: 100%;
      height: 104px;
      display: flex;
      justify-content: space-between;
      padding: 40px 16px 16px 16px;
      > h1 {
        font-size: 30px;
        font-weight: bold;
      }
      > .time_wrap {
        > .ampm {
          font-size: 20px;
          font-weight: 500;
          margin-right: 8px;
        }
        > .time {
          font-size: 40px;
          font-weight: 300;
        }
      }
    }
    > .btn_wrap {
      display: flex;
      justify-content: space-between;
      padding: 0px 16px;
      > .graph_tap_wrap {
        width: 208px;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 25px;
        background-color: white;
        > button {
          width: 96px;
          height: 40px;
          border-radius: 25px;
          color: #a4a4a4;
          background-color: white;
          border: 0px;
          cursor: pointer;
          transition: 0.4s;
        }
        > .select {
          color: white;
          background-color: #396030;
          box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
        }
      }
      > .indicator_wrap {
        width: 816px;
        height: 48px;
        background-color: white;
        border-radius: 25px;
        padding: 0px 48px;
        > ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > li {
            transition: 0.4s;
          }
          > .active {
            color: #32a1ff;
          }
        }
      }
      > .date_picker_wrap {
        > .react-datepicker-wrapper {
          > .react-datepicker__input-container {
            > .custom_input_wrap {
              width: 448px;
              height: 48px;
              font-size: 16px;
              text-align: center;
              border: none;
              border-radius: 30px;
              color: #111111;
              background-color: white;
              cursor: pointer;
              > svg {
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 50%;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
              }
              &:hover {
                > svg {
                  > rect {
                    fill: #2f5427;
                  }
                  > path {
                    fill: white;
                  }
                }
              }
            }
          }
        }
        .react-datepicker {
          border-radius: 30px;
          border: none;
          box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
          .react-datepicker__header {
            // 헤더
            background-color: white;
            border-radius: 30px;
            border-bottom: none;
            font-size: 25px;
            line-height: 25px;
            padding-bottom: 20px;
            > .calender_header_wrap {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 10px 20px 10px;
              color: #111111;
              > button {
                font-size: 24px;
                font-weight: 700;
                border: none;
                background-color: transparent;
                margin: 0px 16px;
                cursor: pointer;
              }
            }
            > .react-datepicker__day-names {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 40px;
              font-size: 16px;
              padding: 0px 16px;
              .react-datepicker__day-name {
                // 월 금
                color: #5b5b5b;
                font-size: 14px;
              }
              .react-datepicker__day-name:first-child {
                color: red;
              }
            }
          }
        }
        .calender_wrap {
          // 날짜 파트
          background-color: white;
          .react-datepicker__day {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            line-height: 50px;
          }
          .react-datepicker__day--selecting-range-end,
          .react-datepicker__day--in-selecting-range,
          .react-datepicker__day--keyboard-selected,
          .react-datepicker__day--in-range {
            // background-color: #2f5427;
            color: white;
          }
          .react-datepicker__day--outside-month {
            color: rgb(200, 200, 200);
          }
        }
      }
      > .download_wrap {
        cursor: pointer;
        > svg {
          border-radius: 50%;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
        }
        &:hover {
          > svg {
            > .circle {
              fill: #2f5427;
            }
            > rect {
              fill: white;
            }
            > path {
              fill: white;
            }
          }
        }
      }
    }
    > .content_wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 16px 16px 0px 16px;
      > .custom_line_graph_wrap {
        padding: 16px;
        background-color: white;
        > .info_wrap {
          display: flex;
          justify-content: space-between;
          font-size: 23px;
          line-height: 33px;
          font-weight: 500;
          padding-bottom: 16px;
        }
        > .graph_wrap {
          width: 100%;
          height: 360px;
          border: none;
          border-radius: 30px;
          overflow: hidden;
          box-shadow: inset 4px 4px 4px 0px rgba(0, 0, 0, 0.05);
          background-color: #f7f7f7;
          padding: 10px 10px 0px 0px;
        }
      }
      > .custom_line_graph_wrap:first-child {
        margin-bottom: 16px;
      }
      .custom-tooltip {
        background-color: white;
        border-radius: 4px;
        padding: 8px;
        font-size: 12px;
        > .value_wrap {
          padding-top: 4px;
          > .value {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-width: 120px;
            > .name_wrap {
              display: flex;
              align-items: center;
              margin-right: 25px;
              > .ball {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }
}

// react-datepicker__day
// react-datepicker__day--017
// react-datepicker__day--keyboard-selected
// react-datepicker__day--in-selecting-range
// react-datepicker__day--selecting-range-start
// react-datepicker__day--selecting-range-end
