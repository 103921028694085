.App {
  width: 1920px;
  height: 1080px;
  letter-spacing: -1px;
}

.br_30 {
  border-radius: 30px;
}
.bc_w {
  background-color: white;
}
.bs_1 {
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
}
