.level_1 > #line {
  animation: waveAnimLine1 5s infinite;
}
.level_1 > #wave {
  animation: waveAnim1 5s infinite;
}
.level_2 > #line {
  animation: waveAnimLine2 5s infinite;
}
.level_2 > #wave {
  animation: waveAnim2 5s infinite;
}
.level_3 > #line {
  animation: waveAnimLine3 5s infinite;
}
.level_3 > #wave {
  animation: waveAnim3 5s infinite;
}
@keyframes waveAnimLine2 {
  0% {
    d: path("M 0 0.5 C 0.3 0.7, 0.7 0.3, 1 0.5");
  }
  25% {
    d: path("M 0 0.4 C 0.3 0.4, 0.7 0.8, 1 0.6");
  }
  50% {
    d: path("M 0 0.5 C 0.3 0.8, 0.7 0.2, 1 0.5");
  }
  75% {
    d: path("M 0 0.4 C 0.3 0.3, 0.7 0.7, 1 0.6");
  }
  100% {
    d: path("M 0 0.5 C 0.3 0.7, 0.7 0.3, 1 0.5");
  }
}
@keyframes waveAnim2 {
  0% {
    d: path("M 0 0.5 C 0.3 0.7, 0.7 0.3, 1 0.5 V 1 H 0");
  }
  25% {
    d: path("M 0 0.4 C 0.3 0.4, 0.7 0.8, 1 0.6 V 1 H 0");
  }
  50% {
    d: path("M 0 0.5 C 0.3 0.8, 0.7 0.2, 1 0.5 V 1 H 0");
  }
  75% {
    d: path("M 0 0.4 C 0.3 0.3, 0.7 0.7, 1 0.6 V 1 H 0");
  }
  100% {
    d: path("M 0 0.5 C 0.3 0.7, 0.7 0.3, 1 0.5 V 1 H 0");
  }
}
@keyframes waveAnimLine1 {
  0% {
    d: path("M 0 0.7 C 0.3 0.9, 0.7 0.5, 1 0.7");
  }
  25% {
    d: path("M 0 0.6 C 0.3 0.6, 0.7 1, 1 0.8");
  }
  50% {
    d: path("M 0 0.7 C 0.3 1, 0.7 0.4, 1 0.7");
  }
  75% {
    d: path("M 0 0.6 C 0.3 0.5, 0.7 0.9, 1 0.8");
  }
  100% {
    d: path("M 0 0.7 C 0.3 0.9, 0.7 0.5, 1 0.7");
  }
}
@keyframes waveAnim1 {
  0% {
    d: path("M 0 0.7 C 0.3 0.9, 0.7 0.5, 1 0.7 V 1 H 0");
  }
  25% {
    d: path("M 0 0.6 C 0.3 0.6, 0.7 1, 1 0.8 V 1 H 0");
  }
  50% {
    d: path("M 0 0.7 C 0.3 1, 0.7 0.4, 1 0.7 V 1 H 0");
  }
  75% {
    d: path("M 0 0.6 C 0.3 0.5, 0.7 0.9, 1 0.8 V 1 H 0");
  }
  100% {
    d: path("M 0 0.7 C 0.3 0.9, 0.7 0.5, 1 0.7 V 1 H 0");
  }
}
@keyframes waveAnimLine3 {
  0% {
    d: path("M 0 0.2 C 0.3 0.5, 0.7 0.1, 1 0.3");
  }
  25% {
    d: path("M 0 0.2 C 0.3 0.2, 0.7 0.6, 1 0.4");
  }
  50% {
    d: path("M 0 0.3 C 0.3 0.6, 0.7 0, 1 0.3");
  }
  75% {
    d: path("M 0 0.2 C 0.3 0.1, 0.7 0.5, 1 0.4");
  }
  100% {
    d: path("M 0 0.3 C 0.3 0.5, 0.7 0.1, 1 0.3");
  }
}
@keyframes waveAnim3 {
  0% {
    d: path("M 0 0.2 C 0.3 0.5, 0.7 0.1, 1 0.3 V 1 H 0");
  }
  25% {
    d: path("M 0 0.2 C 0.3 0.2, 0.7 0.6, 1 0.4 V 1 H 0");
  }
  50% {
    d: path("M 0 0.3 C 0.3 0.6, 0.7 0, 1 0.3 V 1 H 0");
  }
  75% {
    d: path("M 0 0.2 C 0.3 0.1, 0.7 0.5, 1 0.4 V 1 H 0");
  }
  100% {
    d: path("M 0 0.3 C 0.3 0.5, 0.7 0.1, 1 0.3 V 1 H 0");
  }
}
