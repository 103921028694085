.detail_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f8f8f8;
  > .tap_wrap {
    width: 96px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    > a {
      padding-top: 16px;
    }
    > ul {
      > li {
        padding-top: 16px;
        cursor: pointer;
      }
    }
  }
}
