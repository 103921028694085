#section_3 {
  width: 610px;
  height: 100%;
  color: #111111;
  > .outdoor_data_warp {
    width: 100%;
    height: 784px;
    padding: 0px 32px 30px 32px;
    background-color: #f8f8f8;
    border-bottom-left-radius: 30px;
    box-shadow: -10px 4px 30px 0px rgba(0, 0, 0, 0.15);
    > .header {
      width: 100%;
      height: 83px;
      display: flex;
      justify-content: space-between;
      align-items: end;
      padding-bottom: 15px;
      > h2 {
        font-size: 30px;
        font-weight: bold;
      }
      > .time_warp {
        > .ampm {
          font-size: 20px;
          font-weight: 500;
          padding-right: 8px;
        }
        > .time {
          font-size: 40px;
          font-weight: 300;
        }
      }
    }
    > .weather_temp_wrap {
      width: 100%;
      height: 170px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 21px 34px;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      > .weather_wrap {
        > .text {
          font-size: 20px;
          font-weight: bold;
        }
      }
      > .temp_wrap {
        > .temp {
          font-size: 70px;
          font-weight: bold;
        }
        > span {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    > .position_date_wrap {
      width: 100%;
      height: 88px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      padding: 29px 37px;
      font-size: 20px;
      font-weight: 500;
      > .position_wrap {
        display: flex;
        align-items: center;
        > img {
          margin-right: 37px;
        }
      }
    }
    > .outdoor_sensor_wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      > .sensor_wrap {
        width: 265px;
        height: 182px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 20px;
        margin-top: 16px;
        > .upper {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 500;
          color: #bfbfbf;
        }
        > .middle {
          display: flex;
          flex-direction: column;
          align-items: center;
          > .value {
            font-size: 50px;
            font-weight: bold;
            height: 60px;
          }
          > .grade {
            font-size: 20px;
            font-weight: 500;
          }
        }
        > .under {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > .name {
            font-size: 20px;
            font-weight: bold;
          }
          > .unit {
            font-size: 12px;
            font-weight: bold;
            color: #bfbfbf;
          }
        }
        > .level_0,
        .level_1 {
          // 0:매우좋음 (생략), 1:좋음, 2:보통, 3:나쁨, 4:매우나쁨
          color: #32a1ff;
        }
        > .level_3 {
          color: #fd9b5a;
        }
        > .level_4 {
          color: #dc0000;
        }
      }
    }
  }
  > .building_monitoring_wrap {
    > h2 {
      width: 100%;
      height: 96px;
      padding: 38px 0px 15px 5px;
      font-size: 30px;
      font-weight: bold;
    }
    > .card_wrap {
      display: flex;
      > div {
        width: 279px;
        height: 173px;
        padding: 10px 20px 18px 20px;
        margin-right: 15px;
        > .upper {
          display: flex;
          justify-content: space-between;
          align-items: start;
          > .name {
            font-size: 12px;
            font-weight: 500;
            color: #bfbfbf;
          }
        }
      }
      > .fan_wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > .upper {
          > .status {
            display: flex;
            align-items: center;
            > span {
              font-size: 14px;
              font-weight: 500;
              margin-right: 8px;
            }
            > #circle {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #7aff58;
            }
          }
        }
        > .under {
          display: flex;
          justify-content: space-between;
          align-items: end;
          > .level {
            font-size: 30px;
            font-weight: bold;
          }
          > img {
            animation: rotate_image 16s linear infinite;
            // transform-origin: 50% 50%;
          }
        }
      }
      > .energy_wrap {
        > .upper {
          > .value {
            font-size: 40px;
            font-weight: bold;
          }
        }
        > .under {
          display: flex;
          justify-content: space-between;
          align-items: end;
          > .level {
            font-size: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@keyframes rotate_image {
  100% {
    transform: rotate(360deg);
  }
}
